<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    hide-default-footer
    :items-per-page="10000"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.date | utc_to_local_datetime("DD-MM-YYYY") }}</td>
          <td class="text-right">{{ item.clicks | local_numbers }}</td>
          <td class="text-right">{{ item.unique_clicks | local_numbers }}</td>
          <td class="text-right">{{ item.sales | local_numbers }}</td>
          <td class="text-right">{{ item.conversion }}%</td>
          <td class="text-right">DKK {{ item.value | local_numbers }}</td>
          <td class="text-right">DKK {{ item.commission | local_numbers }}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: "Date",
        align: "left",
        // sortable: false,
        value: "date"
      },
      {
        text: "Clicks",
        align: "right",
        // sortable: false,
        value: "clicks"
      },
      {
        text: "Unique clicks",
        align: "right",
        // sortable: false,
        value: "unique_clicks"
      },
      {
        text: "Sale",
        align: "right",
        // sortable: true,
        value: "sales"
      },
      {
        text: "Conversion rate",
        align: "right",
        // sortable: true,
        value: "conversion"
      },
      {
        text: "Revenue",
        align: "right",
        // sortable: true,
        value: "value"
      },
      {
        text: "Commission",
        align: "right",
        // sortable: true,
        value: "commission"
      }
    ]
  }),
  methods: {
    goToCampaign(campaign) {
      this.$router.push({ name: "campaign", params: { id: campaign.id } });
    }
  }
};
</script>
